import React from "react"

import Layout from "../components/Layout"
import Menu from "../components/Menu"

import SEO from "../components/seo"

import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const Blog = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Blog" />
      <Menu/>
    </Layout>
  </ThemeProvider>
)

export default Blog